.blocks {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.card {
    width: 150px;
    height: 150px;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.card img {
    width: 60px;
}

.card-text {
    font-size: 17px;
    padding: 0;
    margin: 0;
}
.card-value {
    font-size: 25px;
    padding: 0;
    margin: 0;
}

.download {
    border: 0;
    background-color: #004B77;
    color: #FFFFFF;
    font-size: 25px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 30px;
}

.download:hover {
    background-color: #04080b;
}

.main_buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 1024px) {
    .main_buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .main_buttons > * {
        margin-top: 20px;
    }
}

