.button {
    border: 0;
    background-color: #004B77;
    color: #FFFFFF;
    font-size: 22px;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 15px;
    transition: .5s;
    display:flex;
    align-items: center;
    justify-content: center;
}

.secondary {
    color: #0e0e0e;
    border: 2px solid #0e0e0e;
    background-color: #0000;
}

.secondary:hover {
    background-color: #c8c8c87a;
}

.primary {
    background-color: #004B77;
    color: #FFFFFF;
}

.primary:hover {
    background-color: #04080b;
}

.button_icon {
    margin-right: 5px;
    display: flex;
}
