.titleInput {
    color: #004b77;
}

.inputStyle {
    border: 2px solid #004b77;
}

.profileUpdate {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
