.input {
    background-color: #FFFFFF;
    border: 0;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 22px;
    letter-spacing: 2px;
    color: #545555;
}

.input__title {
    padding-left: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    color: #FFFFFF;
}

.input__error {
    padding-left: 10px;
    padding-top: 2px;
    font-size: 18px;
    color: #743030;
}
