.tabs-line {
    width: 100%;
    background-color: #2D83B5;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
}

.tabs-button {
    color: #FFFFFF;
    font-size: 25px;
    cursor: pointer;
    background-color: #2D83B5;
    border: 0;
    border-right: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF00;
    margin-right: 16px;
    padding-right: 16px;
}

.tabs-button-active {
    border-bottom: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF00;
}

.InfoProduct {
    display: flex;
    padding: 20px;
    background: #FFFFFF;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .InfoProduct {
        display: flex;
        padding: 0px;
        background: #FFFFFF;
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    .InfoProduct > * {
        margin-bottom: 20px;
    }

    .tab-settings > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
