.product_edit__input {
    display: flex;
    justify-content: space-between;
}

.product_edit__action {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
