.sidebarMenu {
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: sticky;
    background-color: #323a49;
    padding: 10px;
}

.closeMenu {
    left: -250px;
}

.openMenu {
    left: 0px;
}

@media (max-width: 1024px) {
    .sidebarMenu {
        position: fixed;
        z-index: 999;
        height: 100%;
    }
    .sidebarMenu > * {

    }
}

.sidebar-logo-container {
    display: flex;
    align-items: center;
}

.sidebar-logo-container img {
    width: 50%;
    padding: 2em;
}

.sidebar-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-items {
    height: 100%;
    width: 100%;
}

.sidebar-item {
    padding: 1.5em;
    padding-left: 2em;
    display: flex;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.sidebar-item:hover {
    background-color: #004B77;
}

.sidebar-item-active {
    padding: 1.5em;
    padding-left: 2em;
    display: flex;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    background-color: #004B77;
    cursor: pointer;
}

.sidebar-item-icon {
    width: 20px;
}

.sidebar-item-label {
    font-size: small;
    font-weight: 600;
    margin-left: 15px;
}

.sidebar-footer {
    padding: 1.5em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.logo {
    color: #FFFFFF;
    font-size: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeMenuIcon {
    color: #ffffff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333a49;
}

.openMenuIcon {
    color: #ffffff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333a49;
    margin-right: -50px;
}

.openMenuIcon svg {
    width: 50px;
    height: 50px;
}

