.dashboard-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: auto;
}

.dashboard-content-container {
    margin: 1em;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap:25px;
    overflow: auto;
}

.dashboard-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .dashboard-content-header {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-top: 50px;
    }
}

.dashboard-content-header h2 {
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    font-size: xx-large;
    font-weight: 700;
}

.dashboard-content-search {
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 0.009em;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    border: 1px solid gray;
}

.dashboard-content-search:focus {
    outline: #2D83B5 auto 1px;
}

.dashboard-content-input {
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    border: 0px;
    box-sizing: content-box;
    background: none transparent;
    margin: 0px;
    display: block;
    min-width: 0px;
    width: 100%;
    color: #000000;
    font-size: 0.75rem;
    padding: 0.625rem;
}

table {
    width: 100%;
    margin: auto;
}

th {
    text-align: left;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    color: #2D83B5;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.035em;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
}

tbody {
    display: table-row-group;
}

tr, thead {
    color: inherit;
    vertical-align: middle;
    outline: 0px;
}

td {
    text-align: left;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
}

td div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboard-content-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
}

td span {
    margin: 0px;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: gray;
    font-weight: 700;
}

.dashboard-content-icon {
    width: 20px;
    margin-right: 10px;
}

.dashboard-content-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dashboard-content-footer .pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    background-color: #F0F2F5;
    cursor: pointer;
}

.dashboard-content-footer .active-pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #2D83B5;
    cursor: pointer;
}

.empty-table {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    color: gray;
    font-weight: 700;
    border-color: #FFFFFF;
}
