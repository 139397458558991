.chart{
    width: 100%
}
.chart > *{
    width: 100%
}

.badge {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 1024px) {
    .badge {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .main_buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .main_buttons > * {
        margin-top: 20px;
    }
}

