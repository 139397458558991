.register-page__container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(#015C92, #2D83B5, #89CCF6);
}

.register-page__container__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register-page__container__form > h1 {
    margin: 0;
    margin-bottom: 20px;
    color: #FFFFFF;
}

.register-page__container__form > form > * {
    margin-bottom: 20px;
    width: 100%;
}

.register-page__container__footerForm {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.register-page__container__footerForm > * {
    width: 100%;
}
